<!--
	Relatorio Base Stimulsoft
	Esta aplicacao abre o editor na tela de relatorios manutenção, quando o usuário clica no list e escolhe o relatório
-->
<template>
  <div id="RelatoriosComponente" ref="RelatoriosComponente" class="pa-0 pb-1">
    <!-- Modal de Inclusão ou Edição do Relatório -->
    <v-dialog
      v-if="dialog_duplicar"
      :value="true"
      persistent
      style="width: 450"
      :max-width="450"
      :retain-focus="false"
    >
      <RelatoriosManutencaoModal
        :dialog_duplicar.sync="dialog_duplicar"
        :dados_duplicar.sync="dados_duplicar"
        acao_duplicar="E"
        ref="RelatoriosManutencaoModal"
      />
    </v-dialog>
		<div id="viewerContent" class="div1"></div>

  </div>
</template>

<script>

var viewer      = null;
var designer    = null;

import store_site from "../../store/store_site";
import store_usuario from "../../store/store_usuario";
import RelatoriosManutencaoModal from './RelatoriosManutencaoModal.vue';
import { IniciaStimulsoft, Relatorio } from "../../services/global"

export default {
  name : "RelatoriosComponente",

  components: {
    RelatoriosManutencaoModal
  },

  props: ['Relatorio', 'detalhes_', 'cod_relatorio', 'parametros'],

  data() {
    return {
    	store_site        	: store_site,

			dialog_duplicar	  	: false,
			dados_duplicar			: null,
    }
  },

  // computed: {
  //   painel: function() {
  //      	return this.Relatorio.conteudo_stimulsoft;
  //   },
  // },

	mounted() {
		//window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkcgIvwL0jnpsDqRpWg5FI5kt2G7A0tYIcUygBh1sPs7plofUOqPB1a4HBIXJB621mau2oiAIj+ysU7gKUXfjn/D5BocmduNB+ZMiDGPxFrAp3PoD0nYNkkWh8r7gBZ1v/JZSXGE3bQDrCQCNSy6mgby+iFAMV8/PuZ1z77U+Xz3fkpbm6MYQXYp3cQooLGLUti7k1TFWrnawT0iEEDJ2iRcU9wLqn2g9UiWesEZtKwI/UmEI2T7nv5NbgV+CHguu6QU4WWzFpIgW+3LUnKCT/vCDY+ymzgycw9A9+HFSzARiPzgOaAuQYrFDpzhXV+ZeX31AxWlnzjDWqpfluygSNPtGul5gyNt2CEoJD1Yom0VN9fvRonYsMsimkFFx2AwyVpPcs+JfVBtpPbTcZscnzUdmiIvxv8Gcin6sNSibM6in/uUKFt3bVgW/XeMYa7MLGF53kvBSwi78poUDigA2n12SmghLR0AHxyEDIgZGOTbNI33GWu7ZsPBeUdGu55R8w="
		//window.Stimulsoft.StiOptions.WebServer.url = "http://localhost:30006";

		if (!window.Stimulsoft)
		  IniciaStimulsoft();
		
		if (this.Relatorio) {
			this.relatorio_conteudo = this.Relatorio.conteudo_stimulsoft;
			this.createViewer();
      this.setReport(null);
		}
		else if (this.cod_relatorio) {
			const abre_rel = async(p_cod_relatorio, p_parametros) => {
				let parametros = [];
				if (p_parametros) {
					let paramAux = null;
					paramAux = JSON.parse(p_parametros);
					if (!Array.isArray(paramAux)) {
						parametros.push(paramAux);
					}
					else {
						parametros = paramAux;
					}
				}
				parametros.push({ Name: "cod_usuario", Value: store_usuario.user.cod_usuario });
				let body = {
					cod_relatorio: p_cod_relatorio,
					tipo: "json_conteudo",
					parametros: parametros,
				};
				let lo_Rel = await Relatorio(body);
				this.relatorio_conteudo = lo_Rel;
				this.createViewer();
		    this.setReport(null);
			}
			abre_rel(this.cod_relatorio, this.parametros)
		}
  },

  methods: {

		seta_dialog(rel) {
			this.dados_duplicar = rel;
			this.dialog_duplicar = true;
			document.getElementById("StiDesigner").style.zIndex = "99";
		},

    createViewer() {
			// Specify necessary options for the viewer
console.log('window.Stimulsoft', window.Stimulsoft)
			var options = new window.Stimulsoft.Viewer.StiViewerOptions();
////++++			options.height = "100%";
	 			options.width = "100%";

// 			options.appearance.allowTouchZoom = true;
 			//options.appearance.backgroundColor = t {name: "White", _a: 255, _r: 255, _g: 255, _b: 255},
// 			options.appearance.bookmarksPrint = false
// 			options.appearance.bookmarksTreeWidth = 180
// 			options.appearance.chartRenderType = 3
// 			options.appearance.datePickerFirstDayOfWeek = 0
				options.appearance.fullScreenMode = false
// 			options.appearance.htmlRenderMode = 3
	 			options.appearance.interfaceType = 0
// 			options.appearance.openExportedReportWindow = "_blank"
// 			options.appearance.openLinksWindow = "_blank"
// 			options.appearance.pageAlignment = 1
 			//options.appearance.pageBorderColor = t {name: "Gray", _a: 255, _r: 128, _g: 128, _b: 128},
// 			options.appearance.parametersPanelColumnsCount = 2
	 			options.appearance.parametersPanelDateFormat = "dd/MM/YYYY"
 			// options.appearance.parametersPanelMaxHeight = 300
// 			options.appearance.parametersPanelPosition = 0
// 			options.appearance.parametersPanelSortDataItems = true
// 			options.appearance.reportDisplayMode = 3
// 			options.appearance.rightToLeft = false
////++++ 			options.appearance.scrollbarsMode = true
// 			options.appearance.showPageShadow = false
 			options.appearance.showTooltips = true
 			options.appearance.showTooltipsHelp = false
			//options.toolbar.showAboutButton 	= false;
			options.toolbar.showOpenButton		= false;
			
			// O options.toolbar desta forma dá erro na versão 2023, os elementos tem de ser um a um como descritos abaixo
			// options.toolbar	= 	{ elementos }
			options.toolbar.showDesignButton 		= this.detalhes_;
			options.toolbar.showAboutButton 		= false;
			options.toolbar.ShowSendEmailButton = true;
			options.toolbar.viewMode				  	= 0;
											// viewMode 			: { 0: "SinglePage",
											// 						1: "Continuous",
											// 						2: "MultiplePages",
											// 						3: "OnePage",
											// 						4: "WholeReport",
											// 						5: "MultiPage"
											// 						},

			//printDestination 	: Stimulsoft.Viewer.StiPrintDestination.Direct,
			//borderColor 		: Stimulsoft.System.Drawing.Color.navy,
			//showPrintButton 	: false,
			//showViewModeButton: true,
			//zoom 				: 50;
//			}
// 			options.exports = {	showExportDialog: true,
// 								showExportToCsv: true,
// 								showExportToDbf: false,
// 								showExportToDif: false,
// 								showExportToDocument: true,
// 								showExportToExcel: false,
// 								showExportToExcel2007: true,
// 								showExportToExcelXml: false,
// 								showExportToHtml: true,
// 								showExportToHtml5: true,
// 								showExportToImageBmp: false,
// 								showExportToImageGif: false,
// 								showExportToImageJpeg: false,
// 								showExportToImageMetafile: false,
// 								showExportToImagePcx: false,
// 								showExportToImagePng: false,
// 								showExportToImageSvg: false,
// 								showExportToImageSvgz: false,
// 								showExportToImageTiff: false,
// 								showExportToMht: false,
// 								showExportToOpenDocumentCalc: true,
// 								showExportToOpenDocumentWriter: true,
// 								showExportToPdf: true,
// 								showExportToPowerPoint: true,
// 								showExportToRtf: false,
// 								showExportToSylk: false,
// 								showExportToText: true,
// 								showExportToWord2007: true,
// 								showExportToXml: false,
// 								showExportToXps: false,
// 								storeExportSettings: true,
// 							}

			// Create an instance of the viewer
			viewer = new window.Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);
			var Relatorio_ =  this.Relatorio
			var store_site_ = this.store_site
			var seta_dialog_ = this.seta_dialog;
			// Add the design button event
			viewer.onDesignReport = function (e) {
				this.visible = false;
				//if (designer == null) {
  				var optionsDesigner = new window.Stimulsoft.Designer.StiDesignerOptions();
					//optionsDesigner.appearance.fullScreenMode = true;
					//optionsDesigner.appearance.fullScreenMode = false;
					optionsDesigner.appearance.htmlRenderMode = window.Stimulsoft.Report.Export.StiHtmlExportMode.Table;
					optionsDesigner.width  = "100%";
					//optionsDesigner.height = "100%";
					// Create an instance of the designer
					designer = new window.Stimulsoft.Designer.StiDesigner(optionsDesigner, "StiDesigner", false);

					// Add the exit menu item event
					designer.onExit = function (e) {
						this.visible = false;
						viewer.visible = true;
					}
					// Salvar Documento
					designer.onSaveReport = async function (args) {

						var jsonReport = args.report.saveToJsonString();
						//console.log(jsonReport);
						Relatorio_.conteudo_stimulsoft = jsonReport

						seta_dialog_(Relatorio_)

					}

					designer.renderHtml("viewerContent");
				//}
				designer.visible = true;
				designer.report = e.report;

			};

			viewer.renderHtml("viewerContent");

		},

		setReport(reportObject) {
			// Forcibly show process indicator
			viewer.showProcessIndicator();
			reportObject = this.relatorio_conteudo;

			// Timeout need for immediate display loading report indicator
			setTimeout(function () {
				// Create a new report instance
				var report = new window.Stimulsoft.Report.StiReport();
				// Load reports from JSON object
				report.load(reportObject);

				// var dbPostgreSQL = report.dictionary.databases.list[0] //.getByName("Conexão");
				// console.log('ZZZZZZZZZZZ 2', dbPostgreSQL)
				// delete dbPostgreSQL.ConnectionStringEncrypted;
        // dbPostgreSQL.connectionString = "Server=186.249.46.198; Port=5432; Database=db_imob_franca_parra_teste;User Id=postgres; Password=manager;Pooling=true;Min Pool Size=0;Max Pool Size=100;Connection Lifetime=0;";

				//var dbPostgreSQL1 = rel_conteudo.relatorio.Dictionary.Databases[0];
				// var dbPostgreSQL1 = report.dictionary.databases.list[0] //.getByName("Conexão");
        // var ls_connString = "Server=186.249.46.198; Port=5432; Database=db_imob_padrao_teste2;User Id=postgres; Password=manager;Pooling=true;Min Pool Size=0;Max Pool Size=100;Connection Lifetime=0;";
        // var ls_Conexao = new Stimulsoft.Report.Dictionary.StiSqlDatabase(dbPostgreSQL1.Name, dbPostgreSQL1.Alias, ls_connString);
        // console.log("EEEEEEEEEE 1 ", dbPostgreSQL1.ConnectionStringEncrypted);
        // dbPostgreSQL1.ConnectionStringEncrypted = ls_Conexao.connectionStringEncrypted;
        // console.log("EEEEEEEEEE 2 ", dbPostgreSQL1.ConnectionStringEncrypted);

				// Assign the report to the viewer
				viewer.report = report;

			}, 50);
		},

		// Set report button style
		setReportButton(button) {
			for (var reportName in reportsCollection) {
				var reportButton = document.getElementById(reportName);
				reportButton.isSelected = reportButton == button;
				reportButton.className = reportButton.isSelected ? "ReportButton Selected" : "ReportButton";
			}
		},

		// Load first report after the page is loaded
		// onBodyLoad() {
		// 	createViewer();
		// 	selectedButton = document.getElementById("Relatorio_31");
		// 	//console.log(selectedButton);
		// 	onButtonClick(selectedButton, Relatorio_31);
		// },

		// Demo Report button events
		// ---

		onButtonClick(button, reportObject) {
			selectedButton.className = "ReportButton";
			button.className = "ReportButton Selected";
			selectedButton = button;
			setReport(reportObject);
		},

		onButtonMouseOver(button) {
			if (button != selectedButton) button.className = "ReportButton Over";
		},

		onButtonMouseOut(button) {
			if (button != selectedButton) button.className = "ReportButton";
		}

  }

}

</script>

<style scoped>

	.div1 {
		height: calc(100vh - 75px);
		/* height: 1300px !important; */
		background-color:#FFFFFF;
	}

</style>